<template>
  <div>
    <transition name="slide-fade" mode="out-in" appear>
      <SubNav :items="activePages"/>
    </transition>
    <div class='main'>
      <Component v-for="page in activePages" :key="page.id" :is="page.component" />        
      <Footer />
    </div>
  </div>
</template>
<script>
import { shallowRef } from 'vue'
import axios from 'axios';

import Footer from '@/components/Footer';
import SubNav from '@/components/SubNav';
import ConcertCalendar from '@/pages/ConcertCalendar';
import Summary from '@/pages/Summary';


const concertCalendar = shallowRef(ConcertCalendar)
const summary = shallowRef(Summary)

export default {
  name: 'Welcome',
  components: {
    Footer,
    SubNav,
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          let myPage = {...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]}; 
          myPage.name = element.title;  //overwrite page name from site with one from server
          pages.push(myPage);
          
        });
        return pages;
    }
  },
  data: () => ({ 
     pages : [
          {
            id: '29',
            path : 'summary',
            hash : 'concert-calendar',
            name : 'Concert Calendar',
            component: concertCalendar
          },  
          {
            id: '10',
            path : 'summary',
            hash : 'summary',
            name : 'Summary',
            component: summary
          },          
      ],
  }),   
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'6'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  methods: {},
}
</script>