<template>
  <div id="summary" class="light-gradient" :style="`background-image:url('https://enmarketarena.vipfanportal.com/upload/${$store.state.summary?.image}')`">
      <div class='add-padding'>
        <div class='limit-width'>
          <PageTitle dark :title="$store.state.summary.title"/>
          <div>
            <div class="copy" v-html="$store.state.summary.copy"/>
            <Button secondary :text="$store.state.summary?.attachmentTitle" v-if="$store.state.summary?.attachment" link :path="$store.state.summary?.attachment"/>
            <div class="copy">
              <Button text='Contact Us'/>
            </div>            
          </div>
          
        </div>
      </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Button from '@/components/Button';
export default {
    name:'Summary',
    components: {    
        Button,    
        PageTitle
    },
}
</script>

<style lang="scss" scoped>
#summary {
    min-height:calc(100vh - 140px);
    overflow: auto;
    background-size: cover;
  }
  h1 {
    max-width: 650px;
    margin-top:22px;
    margin-bottom:12px;
  }  
  h4 {
    max-width: 350px;
  } 
.copy {
  padding:1em 0;
}
</style>