<template>
  <div id="concert-calendar" class="top">     
      <transition name="slide-fade" mode="out-in" appear>
        <div class='limit-width'>
          <h1 class="white">Events Calendar Coming Soon!</h1>
          <div class='cta'>
            <Button text='See Upcoming Events'/>
          </div>
        </div> 
      </transition>
    </div>
</template>

<script>
import Button from '@/components/Button';
export default {
    name:'ConcertCalendar',
    components : {
        Button
    }
}
</script>

<style lang="scss" scoped>
.top {   
    position: relative; 
    overflow: hidden;
    &:before {
      z-index: 1;
    }
    .limit-width {
      position: relative;
      z-index:1;
    }
    .cta {
      margin-top: 20px;
    }
  }
</style>